body {
  font-family: "Lora", serif !important;
  background: rgb(255, 255, 255);
  background: rgb(255, 237, 206);
  background: linear-gradient(
    90deg,
    rgba(255, 237, 206, 1) 5%,
    hsl(38, 100%, 86%) 30%,
    rgba(255, 226, 176, 1) 60%,
    rgba(255, 237, 206, 1) 95%
  );
  height: 100%;
}

p {
  font-size: 1.2em;
}

li {
  font-size: 1.2em;
}

.sideText {
  font-size: 1em;
  line-height: 0.2em;
}

.sideTitle {
  font-size: 1.4em;
}

.cardTitle {
  font-size: 1.4em;
}

.card {
  background-color: #ffe5b7 !important;
}

h1 {
  font-size: 2.1em !important;
}

.carousel-inner > .carousel-item > img {
  height: 500px;
}

.img-wrapper {
  position: relative;
  background-color: #ff4d65;
}

.img-responsive {
  width: 100%;
  height: auto;
}

.img-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: right;
}

.btn-responsive {
  background-color: #fa9189 !important;
  font-size: 1.9em !important;
  font-weight: bold;
}

html {
  position: relative;
  min-height: 100%;
}

body {
  padding-bottom: 60px;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 15px;
  background-color: #fa9189;
  font-size: 0.9em;
}

a[href^="tel"] {
  color: inherit;
  text-decoration: none;
}

a[href^="tel"]:hover {
  color: inherit;
  text-decoration: underline;
}

a[href^="mailto"] {
  color: inherit;
  text-decoration: none;
}

a[href^="mailto"]:hover {
  color: inherit;
  text-decoration: underline;
}

address {
  color: black;
  text-decoration: none;
}

address:hover {
  color: black;
  text-decoration: underline;
}

#home-logo {
  width: 100%;
}

nav {
  background-color: #fa9189;
}

.nav-link {
  font-size: 1.9em !important;
  font-weight: bold;
}

#headerTitle {
  padding-top: 70px;
  padding-left: 20px;
  font-size: 4em !important;
}

@media screen and (max-width: 640px) {
  .carousel-inner > .carousel-item > img {
    height: 200px;
  }

  footer {
    height: 120px;
  }
  body {
    padding-bottom: 120px;
  }
  .languageBtn {
    font-size: 20px !important;
  }
  p {
    font-size: 18px;
  }
  li {
    font-size: 18px;
  }
  h1 {
    font-size: 25px !important;
  }
}
